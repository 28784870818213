.info {
  margin-left: 10px;
}

.mark {
  height: 15px;
  width: 15px;
  border: 2px solid rgb(77, 77, 77);
  border-radius: 100%;
  font-size: 10px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: help;
  color: #333;
}

.tooltip {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  max-width: 250px;
  padding: 5px;
}
