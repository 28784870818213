.container h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-weight: 500;
  font-size: 17px;
  color: #333;
}

.field {
  margin: 0 0 1em;
}

.input {
  position: relative;
  display: flex;
}

.container {
  display: flex;
  height: 100vh;
  background-color: white;
  overflow: hidden;
}

.info {
  position: relative;
  width: 50%;
  background: rgb(252, 189, 1);
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.info .background {
  width: 150%;
  min-height: 100vh;
  opacity: 0.4;
  filter: grayscale(100%) contrast(140%);
  margin-left: -50%;
  margin-top: -50%;
}

.info .logo {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.panel {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
}

.panel .logo {
  display: none;
}

@media (max-width: 1100px) {
  .info {
    display: none;
  }
  .panel {
    width: 100%;
    padding: 20px;
  }
  .panel .logo {
    display: initial;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 60vw;
  }
}

.form {
  background-color: #fff;
  padding: 15px;
  max-width: 400px;
  width: 100%;
  margin: auto;
}

.form .title {
  font-size: 45px;
  font-weight: 900;
  margin-bottom: 80px;
  text-align: center;
}

.buttons {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  margin-top: 40px;
  margin-bottom: 29px;
}

.form .links {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.form .links a {
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  color: #333;
}
.form .links a:hover {
  opacity: 0.7;
}

.form .links a span {
  color: rgb(252, 189, 1);
  margin-left: 4px;
}

.error {
  color: #db4437;
  font-size: 11px;
  margin-bottom: 5px;
  margin-top: 2px;
  font-weight: 500;
  min-height: 13px;
}

.message {
  display: flex;
  align-items: center;
  height: 62px;
  font-size: 14px;
  color: #db4437;
}

.success {
  color: #03af03;
}

@media (max-width: 928px) {
  .form {
    min-width: 70vw;
  }
}
@media (max-width: 720px) {
  .form {
    min-width: 90vw;
  }
}
