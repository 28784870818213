.btn-container {
    position: absolute;
    bottom: 0;
    background: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    opacity: 0.6;
    align-items: center;
    font-weight: bold;
}

.image-buttons {
    cursor: pointer;
    padding: 5px;
    -webkit-user-select: none;
    user-select: none;
    display: grid;
    grid-template-columns: 45% auto 45%;
    width: 100%;
}

.image-buttons>span {
    font-weight: bold;
    margin: 5px;
    font-size: larger;
}

.prev {
    display: flex;
    flex-direction: row-reverse;
}

.next {
    display: flex;
}

.content {
    text-align: center;
    cursor: auto;
}
@media (orientation: portrait) {

    .btn-container {
        width: 90% !important;
    }
}
