.container {
  max-width: 90vw;
  width: 400px;
  padding: 20px;
  max-width: 100%;
}

.title {
  font-size: 23px;
  font-weight: 700;
  margin: 0 10px 25px;
}

.date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form {
}

.content {
  padding: 0 10px;
}

.content input {
  font-weight: 700;
}

.content input::placeholder {
  font-weight: 600;
  color: #bbbbbb;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin: 20px 10px 0;
}

.text {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.textHelper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 4;
  background: #eff2f7;
  font-size: 12px;
  padding: 6px 13px;
  border-radius: 6px;
  font-weight: 700;
}

.textHelper img {
  width: 13px;
  margin: -2px 8px 0 0;
}

.line {
  position: absolute;
  left: 0;
  top: calc(50% - 2px);
  height: 4px;
  width: 100%;
  background: #eff2f7;
}

.exit {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  line-height: 20px;
}

@media (max-width: 400px) {
  .container {
    padding: 20px 10px;
  }
  .title {
    font-size: 18px;
    margin: 0 0 25px;
  }
  .content {
    padding: 0;
  }
  .buttons {
    margin: 20px 0 0;
  }
  .textHelper {
    font-size: 11px;
    padding: 6px 8px;
  }

  .textHelper img {
    width: 11px;
    margin: -1px 4px 0 0;
  }
}
