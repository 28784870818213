.container {
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 15px;
}

.link-icons > button {
    border: none;
    padding: 0;
    margin: 0 5px;
    background: none;
}

.link-icons img {
    max-width: 25px;
    max-height: 25px;
}
