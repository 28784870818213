.btn {
  position: relative;
  font-weight: 700;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.16);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  white-space: nowrap;
}

.full {
  width: 100%;
}

.btn a {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #555;
}

.btn:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08), 0 3px 4px rgba(0, 0, 0, 0.13);
}

.btn:disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.btn:disabled img {
  opacity: 0.5;
}

.tiny {
  font-size: 12px;
  height: 25px;
}

.small {
  font-size: 14px;
  height: 36px;
}

.medium {
  font-size: 16px;
  height: 46px;
}

.big {
  font-size: 18px;
  height: 52px;
}

.paddingNone {
  padding: 0;
}

.paddingSmall {
  padding: 0 5px;
}

.paddingMedium {
  padding: 0 15px;
}

.paddingBig {
  padding: 0 35px;
}

.primary {
  background-color: rgb(252, 189, 1);
  color: #fff;
}
.primary a {
  color: #fff;
}
.primary:hover {
  background-color: rgb(252, 199, 1);
}
.primary:disabled {
  background-color: rgba(252, 189, 1, 0.7);
  color: #f3f3f3;
  opacity: 0.6;
}

.secondary {
  background: white;
  color: #555;
  border: 1px solid rgba(90, 90, 90, 0.3);
}

.default {
  color: #555;
  box-shadow: none;
}

.secondary:disabled {
  border: 1px solid rgba(90, 90, 90, 0.2);
}
.secondary:disabled,
.secondary:disabled a,
.default:disabled,
.default:disabled a {
  color: rgba(85, 85, 85, 0.6);
}

.default:hover,
.secondary:hover {
  background: rgba(190, 190, 190, 0.3);
  box-shadow: none;
}
