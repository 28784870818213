.segment {
  position: relative;
  font-size: 14px;
  margin: 16px 0 26px;
  text-transform: capitalize;
  padding-right: 15px;
}

.segment a {
  color: #5079ff;
  text-decoration: none;
  text-transform: none;
}

.segment-title {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 7px;
  color: #9d9d9d;
  vertical-align: top;
  margin-right: 12px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 100%;
  text-transform: uppercase;
}

.segment-text {
  display: inline-block;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 100%;
  font-size: 15px;
  font-weight: 700;
  color: #5d5d5d;
}

.segment-info {
  margin-left: 10px;
}

.segment-mark {
  height: 15px;
  width: 15px;
  border: 2px solid black;
  border-radius: 100%;
  font-size: 10px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: help;
  color: black;
  padding-left: 1px;
}
