.container {
  height: 55px;
  margin: 5px 0 10px;
}

.slider {
  padding: 0 8px;
}

.label {
  font-size: 12px;
  font-weight: 700;
  color: #565656;
  margin-bottom: 15px;
}

.label span {
  color: #949494;
  margin-left: 5px;
}
