.bar {
  display: flex;
  align-items: center;
  width: 100%;
}

.btn {
  font-size: 15px;
  font-weight: 600;
  margin-right: 15px;
  border-radius: 4px;
  letter-spacing: 0.03rem;
  color: white;
  padding: 8px 20px;
  background: linear-gradient(
    90deg,
    rgb(252, 189, 1) 0%,
    rgb(255, 204, 50) 77%,
    rgb(255, 210, 75) 150%
  );
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.01), 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
}

.btn:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 5px rgba(0, 0, 0, 0.16);
}

.disabled {
  opacity: 0.6;
  cursor: default;
}

.reverse {
  flex-direction: row-reverse;
}

.reverse .btn {
  margin-right: 0;
  margin-left: 15px;
}
