.monthly-income-data {
    display: flex;
    justify-content: space-between;
}

.monthly-income-title {
    text-align: center;
    font-weight: bold;
}

.price {
    font-size: larger;
    color: rgb(252, 189, 1);
    font-weight: bold;
}

.hr {
    margin: 5px;
}

.last-sale-price {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
