.react-calendar-form abbr {
  text-decoration: none !important;
}
.react-calendar-form .react-calendar__tile {
  position: relative;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 20px;
  box-shadow: none;
  text-align: center;
  padding: 5px 0;
  margin: 4px 0;
  font-size: 12px;
  color: #1d2429;
  font-weight: 300;
}

.react-calendar-form .react-calendar__month-view__days__day--neighboringMonth {
  color: #d5dce0;
}

.react-calendar-form .react-calendar__tile:hover::before {
  background: #d9dde3;
}
.react-calendar-form .react-calendar__tile:hover {
  color: #1d2429;
  background: #d9dde3;
}
.react-calendar-form .react-calendar__tile.react-calendar__tile--active {
  border-color: #ffd24b;
  background: #ffd24b;
  border-radius: 0;
  color: #1d2429;
}

.react-calendar-form
  .react-calendar__tile.react-calendar__tile--rangeStart
  abbr,
.react-calendar-form .react-calendar__tile.react-calendar__tile--rangeEnd abbr {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: #fcbd01;
  border-radius: 20px;
}

.react-calendar-form .react-calendar__tile.react-calendar__tile--rangeStart {
  border-radius: 20px 0 0 20px;
}

.react-calendar-form .react-calendar__tile.react-calendar__tile--rangeEnd {
  border-radius: 0 20px 20px 0;
}

.react-calendar-form
  .react-calendar__tile:nth-of-type(7n + 1).react-calendar__tile--active {
  border-radius: 20px 0 0 20px;
}

.react-calendar-form
  .react-calendar__tile:nth-of-type(7n + 7).react-calendar__tile--active {
  border-radius: 0 20px 20px 0;
}

.react-calendar-form
  .react-calendar__tile:nth-of-type(7n
    + 1).react-calendar__tile--active.react-calendar__tile--rangeEnd {
  border-radius: 20px;
}

.react-calendar-form
  .react-calendar__tile:nth-of-type(7n
    + 7).react-calendar__tile--active.react-calendar__tile--rangeStart {
  border-radius: 20px;
}

.react-calendar-form
  .react-calendar__tile.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd {
  border-radius: 20px;
}

.react-calendar-form .react-calendar__month-view__weekdays {
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
}

.react-calendar-form .react-calendar__month-view__weekdays__weekday {
  color: #849095;
  text-transform: capitalize;
}

.react-calendar-form .react-calendar__navigation__label {
  text-transform: capitalize;
  color: #1d2429;
  background: none;
  box-shadow: none;
  border: 0;
  font-weight: 800;
}

.react-calendar-form .react-calendar__navigation__arrow {
  width: 28px;
  height: 28px;
  margin: 0 0.833em;
  border: 0;
  border-radius: 5px;
  background: #eff2f7;
  color: #1d2429;
}

.react-calendar-form .react-calendar__navigation__prev-button {
  padding: 0 2px 2px 0;
}

.react-calendar-form .react-calendar__navigation__next-button {
  padding: 0 0 2px 2px;
}

.react-calendar-form .react-calendar__navigation__arrow:hover {
  background: #d9dde3;
}

.react-calendar-form .react-calendar__navigation__prev2-button,
.react-calendar-form .react-calendar__navigation__next2-button {
  display: none;
}

@media (max-width: 400px) {
  .react-calendar-form .react-calendar__navigation__prev-button {
    margin: 0 5px 0 0;
  }
  .react-calendar-form .react-calendar__navigation__next-button {
    margin: 0 0 0 5px;
  }
  .react-calendar-form .react-calendar__navigation__label {
    font-size: 12px;
  }
}
