.panel {
  position: fixed;
  top: 60px;
  bottom: 0;
  transform: translateX(-400px);
  background-color: #fff;
  z-index: 1;
  transition: 200ms cubic-bezier(0.63, 0.01, 0.28, 0.85);
  width: 400px;
  height: calc(100vh - 60px);
  border-right: 1px solid #ccc;
  padding-bottom: 20px;
  z-index: 99;
  overflow-y: hidden;
  max-width: 80vw;
}

.active {
  transform: translateX(0);
}

.heading {
  padding: 20px;
  height: 60px;
  background: #fcbd01;
  background: linear-gradient(
    90deg,
    rgb(252, 189, 1) 0%,
    rgb(255, 204, 50) 47%,
    rgb(255, 210, 75) 100%
  );
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #fff;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  font-weight: 600;
}

.back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  border: none;
  height: 100%;
}

.wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 120px);
}
