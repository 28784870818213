.segment {
  font-size: 14px;
  margin: 16px 0 26px;
}

.segment a {
  color: #5079ff;
  text-decoration: none;
}

.segment-title {
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 7px;
  color: #9d9d9d;
  vertical-align: top;
  margin-right: 12px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 100%;
  text-transform: uppercase;
}

.segment-text {
  display: inline-block;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 100%;
  font-size: 15px;
  font-weight: 700;
  color: #5d5d5d;
}

.segment-text::first-letter {
  text-transform: capitalize;
}

.title {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 15px;
}

.subtitle {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #848484;
}

.spacer {
  width: 100%;
  height: 10px;
}

.empty {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #848484;
  padding: 20px 30px;
}
