.element {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 15px 20px;
  background: #f6f6f6;
  box-shadow: 0px 0px 3px white;
  transition: all 200ms ease-in-out;
  width: 100%;
  text-align: initial;
}

.element:hover {
  box-shadow: 0px 0px 3px #9e9e9e;
  background: #eeeeee;
  cursor: pointer;
}

.city {
  font-size: 22px;
  font-weight: 700;
  margin-top: -6px;
}

.city span {
  margin-left: 3px;
  font-size: 11px;
  font-weight: 600;
  color: #8b8b8b;
}

.address {
  font-size: 14px;
  font-weight: 600;
}

.spacer {
  flex: 1 1 auto;
}

.link {
  position: relative;
  z-index: 3;
  display: grid;
  grid-gap: 10px;
  height: 60px;
  grid-template-columns: 45px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.link a {
  color: #636363;
  background: #eeecec;
  border: 1px solid #d6d6d6;
  text-decoration: none;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 700;
  transition: all 200ms ease-in-out;
  text-align: center;
  height: fit-content;
  width: fit-content;
}

.link a img {
  width: 25px;
  filter: invert(30%);
}

.link a:hover {
  color: #4b4b4b;
  background: #fcbd01bd;
  text-decoration: none;
}
.link a:focus,
.link a:active {
  color: #454545;
  background: #fcbd01;
  text-decoration: none;
}

.info {
  display: flex;
}

.details {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}

.detail {
  position: relative;
  display: flex;
  padding-right: 10px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #777777;
}

.detail:before {
  position: absolute;
  content: " ";
  width: 4px;
  height: 4px;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  background: #777777;
  border-radius: 100%;
}

.detail b {
  padding-right: 5px;
  color: black;
  text-transform: capitalize;
  font-weight: 700;
}

@media (max-width: 768px) {
  .info {
    flex-direction: column;
  }
  .details {
    padding-left: 0;
  }
  .detail {
    font-size: 13px;
    padding-right: 6px;
    padding-left: 10px;
  }
}
