.container {
  position: relative;
}
.children {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  background: white;
  display: flex;
  white-space: nowrap;
  margin-top: 5px;
  border-radius: 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  max-height: 250px;
  overflow: auto;
}
