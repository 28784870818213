* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.loader {
  border: 5px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.loader-stretch {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-100 {
  height: 100%;
}

.flex-right {
  margin-left: auto;
}
.flex-left {
  margin-right: auto;
}

.btn {
  border-radius: 3px;
  padding: 5px 10px;
  font-weight: 500;
}

.btn:focus {
  background-color: #efeeec;
  outline: none;
}

.btn-primary {
  background-color: #fff;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

* {
  outline: none !important;
}

/* Overwriting google maps styles */
.gm-style-mtc * {
  font-size: 14px !important;
}
.gm-style-mtc div:nth-last-of-type(1) {
  display: none !important;
}
