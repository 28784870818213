.title {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  color: #5e5e5e;
  vertical-align: top;
  margin-bottom: 5px;
  margin-right: 12px;
  margin-left: -3px;
  margin-top: -25px;
  overflow: hidden;
  word-wrap: break-word;
  width: fit-content;
  max-width: 100%;
  text-overflow: ellipsis;
  text-transform: uppercase;
  cursor: pointer;
  padding: 9px 4px;
  border-radius: 4px;
}
.title span {
  width: 10px;
}

.element {
  margin: 7px 0px;
  font-size: 14px;
  font-weight: 700;
  color: #5d5d5d;
}

.element:last-child {
  margin: 7px 0px 35px;
}

.title:hover {
  background: rgba(160, 160, 160, 0.3);
}

.index {
  margin-right: 5px;
}
