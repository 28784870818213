.title {
    font-size: larger;
    font-weight: bolder;
    width: 400px;
    text-align: center;
}

.container {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    height: 20vh;
    min-width: 30vw;
    display: flex;
}

.content {
    padding: 5px;
    margin: 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    flex-direction: column;
}

.reasons-container {
    display: flex;
    margin: 0 5px;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 5px;
}

.reasons-options {
    flex-grow: 1;
    margin: 0 3px;
}

.fetch-reasons-button {
    border-width: thin;
    padding: 0 4px;
    border-radius: 4px;
}

.fetch-reasons-button::after{
    content: '↻';
}

.button-spinner::after {
    visibility: hidden;
}

.button-spinner::before {
    content: '↻';
    position: absolute;
    animation: spin .6s linear infinite;
}

@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}
