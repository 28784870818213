.body {
  padding: 20px;
}

.body-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 120px);
}

.title {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 15px;
}

.subtitle {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #848484;
}

.spacer {
  width: 100%;
  height: 10px;
}

.empty {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #848484;
  padding: 20px 30px;
}

.yearElement span {
  margin-right: 5px;
  color: #333;
}
