.container {
  width: 100%;
  max-height: calc(100vh - 250px);
  min-height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}

.body {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 20px;
  height: calc(100vh - 64px);
  padding: 20px;
}

.heading {
  padding: 20px;
  position: relative;
}

.section {
  margin-bottom: 10px;
}

.title {
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #848484;
}

.grid {
  display: grid;
  grid-template-columns: 1fr auto;
}

@media (max-width: 1000px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}

.copy {
  border: 2px solid rgba(41, 134, 32, 0.4);
  border-radius: 4px;
  font-size: 11px;
  font-weight: 900;
  color: rgb(53, 138, 41);
  max-width: 100px;
}

.apply {
  border: 2px solid rgba(255,165,0, 0.4);
  color: darkorange;
}

.grid .copy:disabled {
  background: rgba(120, 120, 120, 0.25);
  border: 2px solid rgba(120, 120, 120, 0.4);
  color: #555;
  opacity: 0.6;
  cursor: not-allowed;
}

.copy:hover {
  background: rgba(120, 120, 120, 0.15);
}

.filters {
  display: grid;
  align-items: flex-start;
  flex-wrap: wrap;
  grid-gap: 10px;
  margin: 10px 0;
}

.btn {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  width: 100%;
  height: 40px;
  background: white;
  font-size: 13px;
  font-weight: 600;
  border-radius: 4px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
}

.btn span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transform: rotate(135deg);
}

.btn:disabled {
  color: rgba(170, 170, 170);
}

.btn:disabled span {
  border-color: rgba(170, 170, 170);
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.element {
  margin: 5px 0;
  padding: 8px 15px;
  cursor: pointer;
  text-transform: capitalize;
  width: 100%;
  background: transparent;
  box-shadow: none;
  border: 0;
}

.element:hover {
  background: rgba(170, 170, 170, 0.1);
}

.spacer {
  width: 100%;
}

.body {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 20px;
  overflow-y: scroll;
  height: calc(100vh - 64px);
  padding: 20px;
}

.heading {
  padding: 20px;
  position: relative;
}

.section {
  margin-bottom: 10px;
}

.title {
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #848484;
}

.filters {
  display: grid;
  align-items: flex-start;
  flex-wrap: wrap;
  grid-gap: 10px;
  margin: 10px 0;
}

.btn {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  width: 100%;
  height: 40px;
  background: white;
  font-size: 13px;
  font-weight: 600;
  border-radius: 4px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

.btn span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transform: rotate(135deg);
}

.listWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.listWrapper .list {
  overflow: auto;
  max-height: 156px;
}

.element {
  margin: 5px 0;
  padding: 8px 15px;
  cursor: pointer;
  text-transform: capitalize;
  width: 100%;
  background: transparent;
  box-shadow: none;
  border: 0;
}

.element:hover {
  background: rgba(170, 170, 170, 0.1);
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.element {
  margin: 5px 0;
  padding: 8px 15px;
  cursor: pointer;
  text-transform: capitalize;
}

.element:hover {
  background: rgba(170, 170, 170, 0.1);
}

.spacer {
  width: 100%;
}

.label {
  font-size: 13px;
  font-weight: 700;
  color: #5d5d5d;
}

.body {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  overflow-y: scroll;
  height: calc(100vh - 64px);
}

.heading-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.count {
  color: #aeaeae;
}

.heading-checkbox {
  margin-right: 10px;
}

.accordion-button {
  height: 40px;
  transition: 100ms;
  cursor: pointer;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 4px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
}

.accordion-button:hover {
  background-color: #efeeec;
  outline: none;
}

.accordion-button::before {
  display: inline-block;
  content: "";
  height: 5px;
  width: 5px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-135deg);
  transition: 100ms;
}

.accordion-button[aria-expanded="true"]::before,
.accordion-button[aria-selected="true"]::before {
  transform: rotate(45deg);
}

.accordion-button-inset {
  display: inline-flex;
  vertical-align: middle;
  width: calc(100% - 9px);
  padding: 6px;
  font-size: 13px;
  font-weight: 700;
  align-items: center;
  color: #5d5d5d;
}

.accordion-button-img {
  height: 20px;
  margin-right: 10px;
}

.accordion-button-count {
  margin-left: auto;
  font-weight: 700;
  color: #aeaeae;
}

.accordion-node-button {
  width: 100%;
  border: none;
  font-size: 12px;
}

.accordion-node-button:hover,
.accordion-node-button:hover {
  background-color: #efeeec;
}

.accordion-item-no-content {
  padding: 10px;
}

.search {
  position: relative;
  display: flex;
  width: 100%;
}

.search input {
  width: 100%;
  min-height: 44px;
  padding: 0 15px 0 50px;
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.11);
}

.search img {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
}

.empty {
  color: #666;
  padding: 25px 15px;
  font-size: 14px;
}
