.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
}

.window {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 3px;
  overflow: hidden;
  z-index: 2;
  width: 350px;
  max-width: calc(100vw - 40px);
}

.question {
  padding: 15px 25px 25px;
  text-align: center;
}

.window button {
  width: 50%;
  padding: 8px;
  border: 0;
  box-shadow: none;
  border-top: 1px solid #ccc;
  outline: none !important;
}

.window button:hover {
  background: rgba(40, 40, 40, 0.1);
}

.window button:first-of-type {
  border-right: 1px solid #ccc;
}
