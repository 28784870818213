.container {
  margin-top: 30px;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px;
}

.topPanel {
  flex: 1 1 auto;
}

.property {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
}

.address {
  font-size: 18px;
  font-weight: 800;
  margin-top: -5px;
  color: #777777;
}

.goBack {
  margin-top: 15px;
}

.goBack a {
  padding: 8px 20px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
  color: #000;
  text-decoration: none !important;
}
.goBack a:hover {
  background: #f2f2f2;
  color: #000;
}

.details {
  display: flex;
  flex-wrap: wrap;
}

.detail {
  position: relative;
  display: flex;
  padding-right: 10px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #777777;
}

.detail:before {
  position: absolute;
  content: " ";
  width: 4px;
  height: 4px;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  background: #777777;
  border-radius: 100%;
}

.detail b {
  padding-right: 5px;
  color: black;
  text-transform: capitalize;
  font-weight: 700;
}

.noContent {
  height: fit-content;
  min-height: fit-content;
  padding: 30px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  background: #f9f9f9;
}

.buttons {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 10px;
  margin-top: 15px;
}

.buttons button {
  height: auto;
  padding: 7px 20px;
}
