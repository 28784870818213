.image {
    width: fit-content;
    position: relative;
}

.image img {
    height: 100%;
    max-height: 250px;
    width: 100%;
}

@media (orientation: portrait) {
    .image img {
        width: 100% !important;
    }

    .image {
        padding: 20px 20px 0 20px;
    }

    .btn-container {
        width: 90% !important;
    }
}
