.flex {
  position: relative;
  display: flex;
}
@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(0.8, 0.8);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px) rotate(-45deg);
  }
  10% {
    opacity: 1;
    transform: translateY(3px) rotate(-45deg);
  }
  20% {
    transform: translateY(-7px) rotate(-45deg);
  }
  35% {
    transform: translateY(0) rotate(-45deg);
  }
  45% {
    transform: translateY(-5px) rotate(-45deg);
  }
  55% {
    transform: translateY(0) rotate(-45deg);
  }
}

.block {
  position: relative;
  width: 100%;
  height: calc(100vh - 60px);
}

.map {
  width: 100%;
  height: 100%;
}
.map:first-child,
.map:first-child > div:first-child {
  background-color: white !important;
  background: white !important;
}

.loader {
  position: absolute;
  top: 60px;
  left: 10px;
  z-index: 0;
  background: rgba(251, 251, 251, 0.95);
  width: 60px;
  height: 52px;
  border-radius: 3px;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.pin {
  width: 16px;
  height: 16px;
  border-radius: 50% 50% 50% 0;
  background: #fcbd01;
  position: absolute;
  transform: rotate(-45deg);
  left: calc(50% + 10px);
  top: calc(50% + 8px);
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.pin:after {
  content: "";
  width: 8px;
  height: 8px;
  margin: 4px 0 0 4px;
  background: white;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  position: absolute;
  left: calc(50% + 5px);
  top: calc(50% - 4px);
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -15px 0 0 -15px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #fcbd01;
  animation-delay: 1.1s;
}

.text {
  position: absolute;
  left: calc(50% + 1px);
  top: 100%;
  color: #fcbd01;
  transform: translate(-50%, -20px);
  font-size: 11px;
  font-weight: 900;
  text-shadow: -1px 1px 6px #fff;
}

.messages {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-gap: 10px;
}

.message {
  height: 52px;
  transition: 100ms;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 4px;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background: white;
}
.message span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fcbd01;
  font-weight: 900;
  margin-right: 10px;
  border: 2px solid #fcbd01;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.searchBox {
  position: absolute;
  top: 0px;
  left: 170px;
  width: calc(100% - 335px);
  height: 40px;
  z-index: 10;
  background: white;
  margin-top: 10px;
  border-radius: 2px;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  padding: 0 15px;
  font-size: 14px;
}

.mapWrapper {
  height: calc(100vh - 60px);
  flex: 1;
  position: relative;
}
.mapElement {
  height: 100%;
  width: 100%;
}
.mapLoader {
  height: 100%;
  width: 100%;
}

.selectContainer {
  position: absolute;
  top: 0px;
  left: 10px;
  width: 150px;
  z-index: 10;
  margin-top: 10px;
  border: 0;
  font-size: 14px;
}

.selectBtmOk {
  position: relative;
  width: 150px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  border: 0;
}

.selectBtn {
  position: relative;
  width: 150px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  border: 0;
  background: white;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0 30px 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.selectBtn span {
  position: absolute;
  width: 8px;
  height: 8px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transform: rotate(135deg) translate(3px, -4px);
  top: 10px;
  left: calc(100% - 20px);
}

.selectList {
  display: flex;
  flex-direction: column;
}

.selectList button {
  display: flex;
  justify-content: flex-start;
  padding: 0 15px;
  align-items: center;
  width: 150px;
  height: 36px;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #eff2f7;
  text-transform: capitalize;
}

.selectList button:hover {
  background: #eff2f7;
}

.selectList .active {
  background: rgb(238, 214, 109);
}

@media (max-width: 992px) {
  .messages {
    left: 80px;
    transform: translateX(0);
    min-width: 200px;
    width: calc(100vw - 90px);
    max-width: 305px;
  }
  .selectBtn {
    width: 120px;
  }
  .searchBox {
    left: 140px;
    width: calc(100% - 305px);
  }
  .block {
    height: calc(100vh - 60px);
  }
  .message {
    padding: 5px 10px;
    font-size: 12px;
  }
  .message span {
    min-width: 17px;
    min-height: 17px;
    max-width: 17px;
    max-height: 17px;
    font-size: 9px;
    margin-right: 10px;
  }
}
