.container {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    height: 20vh;
    width: 25vw;
    display: flex;
}

.container-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1.5s ease-in, opacity 1.5s ease-in;
}

.content {
    padding: 5px;
    margin: 5px;
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.send-button:active {
    border-radius: 3px;
    box-shadow: inset -13px 11px 19px 7px rgba(0, 0, 0, .08), 0 3px 4px rgba(0, 0, 0, .13);

}

.send-button {
    box-shadow: none;
    border-style: solid;
    border-radius: 3px;
    height: 35px;
    width: 100%;
}

.title {
    font-size: larger;
    font-weight: bolder;
    width: 195px;
    flex-grow: 1;
}

.close {
    position: absolute;
    right: 15px;
    padding: 1px 5px;
    top: 5px;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    cursor: pointer;
}

.close:hover {
    background: rgba(0, 0, 0, 0.1);
}

.error-icon {
    height: 65%;
    width: 100%;
}

.error-container {
    width: 100%;
    height: 100%;
}

.success-icon {
    width: 100%;
    height: 100%;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
