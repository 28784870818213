.container {
  position: relative;
  max-width: 400px;
  max-height: calc(100vh - 60px);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  z-index: 98;
  transform: translateX(0);
  background: white;
  width: 80vw;
}

.panelClosed {
  transform: translateX(0%);
}

.toggleBtn {
  display: none;
  position: absolute;
  top: 50vh;
  right: 0;
  transform: translate(100%, -100%);
  width: 28px;
  height: 60px;
  background: white;
  background-color: white;
  box-shadow: none;
  border: 0;
  border-radius: 0 40px 40px 0;
  box-shadow: 8px 2px 10px rgba(0, 0, 0, 0.15);
  justify-content: center;
  align-items: center;
  padding: 0 8px 0 4px;
  transition: all 150ms ease-in-out;
  cursor: pointer;
}

.toggleBtn span {
  display: block;
  width: 2px;
  height: 18px;
  margin: auto;
  position: relative;
  background: #484848;
  z-index: 1;
}

.panelClosed .toggleBtn {
  background: linear-gradient(
    90deg,
    rgb(252, 189, 1) 0%,
    rgb(255, 204, 50) 77%,
    rgb(255, 210, 75) 150%
  );
  background-color: linear-gradient(
    90deg,
    rgb(252, 189, 1) 0%,
    rgb(255, 204, 50) 77%,
    rgb(255, 210, 75) 150%
  );
  box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.95);
}

@media (max-width: 992px) {
  .container {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    transition: all 150ms ease-in-out;
  }
  .panelClosed {
    transform: translateX(-100%);
  }
  .toggleBtn {
    display: flex;
  }
}

.tabs {
  display: flex;
}

.tabs button {
  cursor: pointer;
  text-transform: uppercase;
  color: #fcbd01;
  font-weight: 900;
  font-size: 14px;
  width: 50%;
  height: 60px;
  border: 0;
  background: transparent;
  box-shadow: 0px 2px 2px rgba(50, 50, 50, 0.1);
}

.tabs button.active {
  text-transform: uppercase;
  color: white;
  font-weight: 900;
  font-size: 14px;
  width: 50%;
  height: 60px;
  border: 0;
  background: #fcbd01;
  background: linear-gradient(
    90deg,
    rgb(252, 189, 1) 0%,
    rgb(255, 204, 50) 47%,
    rgb(255, 210, 75) 100%
  );
  box-shadow: 0px 2px 2px rgba(255, 210, 75, 0.4);
}

.tabs button.selected:disabled {
  background-color: lightgray;
  color: white;
}

.tabs button:disabled {
  cursor: not-allowed;
  color: darkgray;
  background-color: whitesmoke;
}

.content {
  height: 100%;
  max-height: calc(100% - 95px);
  overflow-y: hidden;
}

