.active#sales-tab-btn {
  text-transform: uppercase;
  color: white;
  font-weight: 900;
  font-size: 14px;
  height: 60px;
  border: 0;
  background: #fcbd01;
  background: linear-gradient(180deg,
      rgb(0, 204, 255) 0%,
      rgb(252, 189, 1) 100%);
  box-shadow: 0px 2px 2px rgba(255, 210, 75, 0.4);
}

.regular#sales-tab-btn {
  cursor: pointer;
  text-transform: uppercase;
  color: rgb(0, 204, 255);
  font-weight: 900;
  font-size: 14px;
  width: 50%;
  height: 60px;
  border: 0;
  background: transparent;
  box-shadow: 0px 2px 2px rgba(50, 50, 50, 0.1);
}

.regular#sales-tab-btn:disabled {
  cursor: not-allowed;
  color: darkgray;
  background-color: whitesmoke;
}

.selected#sales-tab-btn:disabled {
  background-color: lightgray;
  color: white;
}

.sales-tab button {
  width: 100%;
}
