.container {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.container > h6 {
  font-size: 0.8rem;
  margin: 0;
  color: #565656;
}

.form {
  display: flex;
}

.form input {
  border-width: 0 0 1px 0;
  text-align: center;
  margin: 0 5px;
  max-width: 105px;
  font-size: 0.8rem;
  border-bottom-color: #949494;
  border-style: solid;
}

.title {
  width: max-content;
  max-width: 125px;
}

.error {
  color: red;
  font-size: x-small;
}

.inputs {
  display: flex;
  flex-direction: column;
}
