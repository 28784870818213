.arrow {
  border-width: 10px 8px 0 8px;
  border-color: white transparent transparent transparent;
  border-style: solid;
  position: absolute;
  left: 50%;
}

.container {
  width: 450px;
  height: 250px;
  background-color: white;
}

@media (orientation: portrait) and (min-width: 600px) {
  .content {
    flex-direction: column;
  }

  #content {
    display: flex;
  }

  .container {
    height: fit-content;
    width: 250px;
  }
}

.content {
  background: white;
  display: grid;
  height: 100%;
  grid-template-columns: 50% 50%;
}

.error-container {
  height: auto;
  min-height: auto;
}
