.usersTitle {
  font-size: 25px;
  font-weight: 600;
  margin-top: 20px;
}

.head {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  padding: 15px 0 25px;
}

.head button:last-of-type img {
  width: 28px;
}

.table {
  box-shadow: 0 1px 2px 1px #dbdbdb;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
}

.group,
.input {
  height: 100% !important;
  box-shadow: none !important;
}

.group img {
  width: 17px;
}

.table thead th {
  padding: 20px 0.75rem 10px;
  font-size: 0.775em !important;
}

.user span {
  font-size: 0.675em;
  font-style: italic;
}

.space {
  margin-right: 7px;
}

.dot {
  display: inline-flex;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #666;
  margin: 0 10px 3px;
}

.user a {
  display: block;
  font-size: 1em;
  padding-top: 3px;
  margin-left: 60px;
  color: #3498db;
  outline: none;
  margin-bottom: -5px;
}

.user img {
  position: relative;
  max-width: 50px;
  float: left;
  margin-right: 15px;
  background: #d4d4d452;
  padding: 5px;
  border-radius: 3px;
}

.table thead tr th {
  text-transform: uppercase;
  font-size: 0.875em;
}
.table thead tr th {
  border-bottom: 2px solid #e7ebee;
}
.table tbody tr td:first-of-type {
  font-size: 1.125em;
  font-weight: 300;
}
.table tbody tr td {
  font-size: 0.875em;
  vertical-align: middle;
  border-top: 1px solid #e7ebee;
  padding: 12px 8px;
}

.table td button:first-of-type {
  margin-right: 10px;
}

.table td button img {
  width: 15px;
  height: auto;
  margin-bottom: 2px;
}

.table td:last-of-type {
  display: flex;
  justify-content: flex-end;
}

.success {
  color: #fff;
  background-color: #00cf2f;
}

.date {
  font-size: 12px !important;
}

.name,
.email {
  font-weight: 400;
}
