.react-calendar-container {
  padding: 20px 30px;
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  background: #ececec;
  margin-bottom: 50px;
}

.react-calendar-container.primary {
  border: 1px solid #d6d6d6;
  background: #373737;
}
.react-calendar-container.primary .react-calendar-tile-user-value div {
  color: #373737;
}

.react-calendar-container.primary * {
  color: white;
}

.react-calendar-container.primary .react-calendar-tile-value {
  color: black;
}

.react-calendar-container.primary button abbr {
  color: black;
}

.react-calendar-container.primary .react-calendar__month-view__days__day {
  border: 0;
}

.react-calendar-container.primary .react-calendar-title {
  font-size: 34px;
  color: white;
}

.react-calendar-container.primary .react-calendar-title::before {
  display: inline-flex;
  content: "";
  border-right: 0.3rem solid #fcbd01bd;
  height: 34px;
  margin: 0 10px 0 0;
  transform: translateY(4px);
}

.react-calendar-container .react-calendar-title {
  font-size: 26px;
  font-weight: 900;
  color: #3c3c3c;
  margin-bottom: 30px;
}

.react-calendar-container .react-calendar__tile {
  min-height: 70px;
}
.react-calendar-container .react-calendar__tile:disabled {
  background: #f2f2f2 !important;
}
.react-calendar-container .react-calendar__tile:disabled abbr {
  color: #bdbdbd !important;
}

.react-calendar-container .react-calendar__month-view__weekdays {
  margin-bottom: 10px;
}

.react-calendar-container .react-calendar__month-view__weekdays__weekday {
  text-align: center;
}

.react-calendar-container .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
  cursor: default !important;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.react-calendar-container .react-calendar__month-view__days__day {
  position: relative;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 0;
  font-size: 16px;
  font-weight: 600;
  background: #f6f6f6;
  border: 1px solid #d6d6d6;
  margin: 3px;
  flex-basis: calc(14.2857% - 6px) !important;
  max-width: calc(14.2857% - 6px) !important;
  transition: all 200ms ease-in-out;
  overflow: visible !important;
}
.react-calendar-container .react-calendar__month-view__days__day:hover {
  background: #ffd24b;
}

.react-calendar-container
  .react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
  cursor: default;
  pointer-events: none;
}

.react-calendar-container .react-calendar-tooltip {
  position: absolute;
  top: -5px;
  left: 50%;
  right: -10px;
  transform: translate(-50%, -100%);
  background: #d7d7d7;
  border: 1px solid #d6d6d6;
  transition: all 20ms ease-in-out;
  display: none;
  border-radius: 3px;
  padding: 10px 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1), 0 5px 8px rgba(0, 0, 0, 0.3);
  color: black !important;
  width: 180px;
}

.react-calendar-container .react-calendar-tooltip:hover {
  display: none !important;
}

.react-calendar-container .react-calendar-tooltip-text {
  color: #464646 !important;
  font-size: 14px;
  font-weight: 700;
}

.react-calendar-container .react-calendar-tooltip-value {
  color: black !important;
  font-weight: 700;
  font-size: 18px;
}

.react-calendar-container .react-calendar-tooltip-info {
  color: #464646 !important;
  font-weight: 700;
  font-size: 16px;
}

.react-calendar-container
  .react-calendar__month-view__days__day:hover
  .react-calendar-tooltip {
  display: block;
}

.react-calendar-container .react-calendar__tile abbr {
  position: absolute;
  top: 3px;
  left: 3px;
  color: #858585 !important;
  font-size: 11px;
  font-weight: 300;
}
.react-calendar-container .react-calendar-tile-value {
  font-size: 17px;
  font-weight: 700;
}

.react-bookmark {
  position: absolute;
  top: 6px;
  right: 4px;
  width: 10px;
  height: 10px;
}

.react-bookmark-inactive {
  opacity: 0.7;
  filter: grayscale(100%);
}

.react-calendar-tile-user-value {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 4px;
  right: 4px;
  font-size: 11px;
  font-weight: 400;
  color: #878787;
}

.react-calendar-tile-user-value span {
  font-size: 12px;
  font-weight: 800;
  color: #636363;
  margin-left: 3px;
}

.react-calendar-tile-user-value span.react-price-over {
  color: #2eb761;
}
.react-calendar-tile-user-value span.react-price-under {
  color: #f54242;
}

@media (max-width: 992px) {
  .react-calendar-tile-user-value div {
    display: none;
  }
}
@media (max-width: 768px) {
  .react-calendar-container .react-calendar-title,
  .react-calendar-container.primary .react-calendar-title {
    font-size: 24px;
    margin-left: 5px;
  }

  .react-calendar-container.primary .react-calendar-title::before {
    margin: 0 10px 0 0;
    transform: translateY(8px);
  }

  .react-calendar-container .react-calendar__tile {
    min-height: 55px;
  }

  .react-calendar-container {
    padding: 10px 5px;
    margin-left: -5px;
    margin-right: -5px;
  }

  .react-calendar-container .react-calendar__month-view__days__day {
    flex-basis: calc(14.2857% - 1px) !important;
    max-width: calc(14.2857% - 1px) !important;
    margin: 0.5px;
  }

  .react-calendar-container .react-calendar__tile abbr {
    font-size: 11px;
  }

  .react-calendar-container .react-calendar-tile-value {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: -7px;
    color: black;
  }
  .react-bookmark {
    width: 9px;
    height: 9px;
  }
  .react-calendar-tile-user-value {
    bottom: 2px;
  }
  .react-calendar-tile-user-value span {
    font-size: 10px;
    margin: 0;
  }
}
