.container {
  display: flex;
  align-items: center;
  padding: 10px;
}

.pages {
  margin-right: 15px;
}

.pages strong {
  margin-right: 5px;
}

.buttons {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  cursor: pointer;
}

.button:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 5px rgba(0, 0, 0, 0.16);
}

.button:disabled {
  background: #e7e7e7;
  opacity: 0.5;
  cursor: default;
}

.button:disabled:hover {
  box-shadow: none;
}

.button img {
  width: 15px;
  height: 15px;
}
