.hubspot-icon {
    width: 25px;
    height: 25px;
}

.hubspot-button>button:disabled {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
}

.hubspot-button>button {
    padding: 0;
    border-radius: 3px;
    margin: 0;
    border: none;
    background-color: transparent;
}
