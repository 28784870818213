.container {
  display: inline-block;
  position: relative;
  width: fit-content;
  margin-bottom: 4px;
  transition: all 150ms ease-in-out;
}

.chip {
  border-radius: 32px;
  font-size: 12px;
  width: fit-content;
  text-shadow: 1px 0px 2px rgba(90, 90, 90, 0.5);
  overflow: hidden;
  margin-right: 4px;
  transition: all 150ms ease-in-out;
}

.overlay {
  padding: 4px 32px 4px 12px;
  border: 0;
  font-weight: 600;
  transition: all 150ms ease-in-out;
}

.overlay:disabled {
  color: black;
}

.chip:hover {
  opacity: 0.7;
}

.container button.delete {
  position: absolute;
  display: inline-flex;
  background: rgba(40, 40, 40, 0.4);
  border: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 0;
  margin: 0 -6px 0 4px;
  cursor: pointer;
  font: inherit;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  right: 17px;
  top: 7px;
}

.delete img {
  height: 8px;
  width: 8px;
}

.delete:hover {
  background: #999;
}
.delete:active {
  background: #777;
}
