.topPanel {
  position: relative;
  display: flex;
  flex-wrap: nowrap !important;
  align-items: center;
  height: 60px;
  z-index: 9999;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
}

.avatar {
  min-width: 38px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 15px 0 25px;
  padding: 5px;
  border-radius: 4px;
  color: white;
  box-shadow: none;
  border: 0;
  background: linear-gradient(
    90deg,
    rgb(252, 189, 1) 0%,
    rgb(255, 204, 50) 77%,
    rgb(255, 210, 75) 150%
  );
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.01), 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.avatar:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 5px rgba(0, 0, 0, 0.16);
}

.menuContainer {
  display: flex;
  height: 100%;
  align-items: center;
}

.logo {
  overflow: hidden;
  margin-left: 5px;
  margin-right: 25px;
  background: transparent;
  border: 0;
  box-shadow: none;
}

.logo img {
  width: 90px;
  height: 20px;
  object-fit: cover;
  transform: scale(0.9);
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  height: 100%;
}

.link a {
  color: #aaaaaacc;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
}

.link a:hover {
  color: #fcbd01;
  text-decoration: none;
}

.activeLink a {
  color: #fcbd01;
}

.actions {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.options {
  position: absolute;
  top: 100%;
  right: 15px;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.01), 0 1px 2px rgba(0, 0, 0, 0.2);
  width: 250px;
  border-radius: 4px;
  border-top: 1px solid rgba(200, 200, 200, 0.2);
  overflow: hidden;
}

.options button {
  background: none;
  border: 0;
  box-shadow: none;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #6f7579;
}

.options button:hover {
  color: rgb(51, 51, 51);
  background: rgb(239, 239, 239);
}

.options .user {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(221, 221, 221, 0.6);
  padding: 10px 20px;
}

.options .username {
  font-size: 20px;
  font-weight: 700;
}

.userImg {
  width: 35px;
  height: auto;
  margin-right: 15px;
  padding: 5px;
  border-radius: 4px;
  margin-top: 2px;
}

.email {
  font-size: 11px;
  font-weight: 500;
  color: #808080;
  margin-top: -4px;
}

.menuBtn {
  width: 38px;
  height: 38px;
  padding: 6px 0px !important;
  margin-left: 10px;
}

.menuBtn span {
  display: block;
  width: 33px;
  height: 4px;
  margin: auto;
  margin-bottom: 5px;
  position: relative;
  background: #484848;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.menuBtn span:first-of-type {
  transform-origin: 0% 0%;
}

.menuBtn span:nth-of-type(2) {
  transform-origin: 0% 100%;
}

.menuOpen span {
  opacity: 1;
  transform: rotate(45deg) translate(4px, -5px);
}
.menuOpen span:nth-of-type(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.menuOpen span:nth-of-type(3) {
  transform: rotate(-45deg) translate(0, 3px);
}

@media (max-width: 992px) {
  .logo {
    display: none;
  }
  .menu {
    position: fixed;
    top: 60px;
    left: 0;
    right: -1px;
    bottom: 0;
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menu .link {
    height: 80px;
  }
  .menu .link a {
    font-size: 20px;
  }
}
