.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  min-height: 500px;
  width: 100%;
}

.text {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
}

.smallText {
  font-size: 20px;
  color: #7b7a7a;
  font-weight: 300;
  text-align: center;
}

.image {
  width: 20%;
  margin-bottom: 20px;
}

.errorText {
  font-size: 18px;
  font-weight: 500;
  color: #646464;
}

.errorText span {
  font-size: 20px;
  margin-right: 6px;
  font-weight: 900;
}
