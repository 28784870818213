.utils {
  display: flex;
  padding: 5px;
}

.utils img {
  height: 18px;
  width: 18px;
}

.utils span {
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
  color: #6c757d;
}

.utils > div {
  flex-grow: 1;
}

.details {
  padding: 0 10px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (orientation: portrait) {
  .details {
    text-align: center;
    width: 100% !important;
    padding: 20px !important;
  }

  .details-button > button {
    flex-grow: 1;
  }

  #description > span:first-of-type {
    white-space: normal;
    overflow: auto;
    text-overflow: unset;
    width: 100%;
  }

  #address {
    white-space: normal;
    overflow: auto;
    text-overflow: unset;
    width: 100%;
    -webkit-line-clamp: unset;
  }
}

@media (max-width: 1040px) {
  #address {
    -webkit-line-clamp: 1;
  }
}

.price-stats {
  display: flex;
  flex-direction: column;
}

.price-stats > span {
  padding-bottom: 5px;
  font-size: large;
  color: rgb(252, 189, 1);
  font-weight: bold;
  text-align: center;
}

.average-stats {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  justify-content: space-between;
}

.average-stats span:nth-of-type(2) {
  font-weight: bold;
  font-size: larger;
}

.details-button > button {
  background: rgb(252, 189, 1);
  color: white;
  border-radius: 3px;
  border-style: none;
  padding: 5px;
  margin: 0 5px 5px 5px;
  font-size: 0.85rem;
  margin-left: auto;
  margin-right: auto;
}

.details-button > button > a {
  color: unset;
  font-weight: bold;
}

.details-button > button > a:hover {
  text-decoration: none;
}

.details-button > button:disabled {
  background: #dddddd;
  font-weight: bold;
}

.details-button {
  display: flex;
  justify-content: space-between;
}

.details-button > div:only-child {
  margin: auto;
}

.description {
  display: flex;
  flex-direction: column;
}

.description > span:first-of-type {
  font-size: 1.1rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-with-link > button {
  border: none;
  padding: 0;
  background-color: #fff;
}

.button-with-link img {
  max-width: 25px;
  max-height: 25px;
}

.address {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
