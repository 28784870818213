.container {
  margin: 20px 40px;
}

.form {
  max-width: 300px;
}

.error {
  font-size: 12px;
  color: red;
  margin-bottom: 10px;
}

.buttons button:first-child {
  margin-right: 10px;
}
