.title {
  font-size: 25px;
  font-weight: 600;
  margin-top: 20px;
}

.pagination {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
}
