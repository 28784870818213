.container {
  position: relative;
  height: 22px;
}
.row {
  display: flex;
  align-items: center;
}
.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 34px;
}
.checkMark {
  width: 34px;
  height: 22px;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 0px 0px 1px 2px rgba(0, 0, 0, 0.03);
  transition: 0.2s;
  border-radius: 25px;
}
.checkMark:before {
  background: #fff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
  bottom: 2px;
  content: "";
  height: 18px;
  left: 2px;
  position: absolute;
  transition: 0.2s;
  width: 18px;
  border-radius: 50%;
}
.activeMark {
  background: rgb(238, 214, 109);
}
.activeMark:before {
  transform: translateX(12px);
  box-shadow: 0px 0px 5px 1px #fcbd01;
}
.label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  flex-wrap: nowrap;
  margin: 0 0 0 10px;
  cursor: pointer;
}
.active {
  top: -20px;
}
