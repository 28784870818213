.body {
  padding: 20px;
}

.title {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 15px;
}

.subtitle {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #848484;
}

.empty {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #848484;
  padding: 20px 30px;
}

.keywords {
  display: flex;
  flex-wrap: wrap;
}

.keywordContainer * {
  margin: 0;
  border: 0;
  width: initial;
  height: initial;
}

body .keywordContainer .keyword {
  font-size: 12px;
  border-radius: 4px;
  padding: 0px 10px;
  margin: 0 4px 4px 0;
}

.monthsElement span {
  color: #9d9d9d;
}
