.frame {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
}

.tiny {
  width: 16px;
  height: 16px;
}
.small {
  width: 30px;
  height: 30px;
}
.medium {
  width: 40px;
  height: 40px;
}
.big {
  width: 60px;
  height: 60px;
}

.child { 
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.primary .child::before {
  background-color: rgb(252, 189, 1);
}

.child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: circleBounceDelay 1.2s infinite ease-in-out both;
  animation: circleBounceDelay 1.2s infinite ease-in-out both;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.16);
}
.circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
