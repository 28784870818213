.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-size: 12px;
  font-weight: 800;
  margin-left: 4px;
}

.input {
  line-height: 1.21429em;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: transparent 0px 0px 0px 0px inset;
  margin: 0px;
  outline: 0px;
  padding: 12px 5px;
  background: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 0;
  transition: color 0.1s ease 0s, border-color 0.1s ease 0s;
  flex: 1 0 auto;
  max-width: 100%;
  text-align: left;
  border: 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 14px;
}
.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.input:-webkit-autofill {
  -webkit-text-fill-color: #909090 !important;
}

.input::placeholder {
  color: rgb(204, 204, 204);
}

.input:focus {
  color: rgba(0, 0, 0, 0.95);
  border-color: #85b7d9;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.35) inset;
}

.error {
  color: #db4437;
  font-size: 11px;
  margin-bottom: 5px;
  margin-top: 2px;
  font-weight: 500;
  min-height: 13px;
}
