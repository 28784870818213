.btn {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
    width: 100%;
    height: 40px;
    background: white;
    font-size: 13px;
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
}

.btn span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(135deg);
}

.btn:disabled {
    color: rgba(170, 170, 170);
}

.btn:disabled span {
    border-color: rgba(170, 170, 170);
}

.list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.element {
    margin: 5px 0;
    padding: 8px 15px;
    cursor: pointer;
    text-transform: capitalize;
    width: 100%;
    background: transparent;
    box-shadow: none;
    border: 0;
}

.element:hover {
    background: rgba(170, 170, 170, 0.1);
}

.listWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.listWrapper .list {
    overflow: auto;
    max-height: 156px;
}

.list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.search {
    position: relative;
    display: flex;
    width: 100%;
}

.search input {
    width: 100%;
    min-height: 44px;
    padding: 0 15px 0 50px;
    border: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 14px;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.11);
}

.search img {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
}

.empty {
    color: #666;
    padding: 25px 15px;
    font-size: 14px;
}
